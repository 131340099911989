<template>
  <div class="dashboard-header">
    <div class="header-container">
      <router-link :to="{ path: '/' }">
        <img src="../../assets/img/iconjama.png" alt="" />
      </router-link>
      <div class="nav">
        <router-link :to="{ path: '/app' }">Dashboard</router-link>
        <router-link :to="{ path: '/app/programs' }">Programas</router-link>
        <router-link :to="{ path: '/app/reports' }">Reportes</router-link>
      </div>
    </div>
    <div class="profile-container">
      <img src="@/assets/img/hacker.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader"
};
</script>

<style lang="scss" scoped>
.dashboard-header {
  height: 60px;
  background-color: #000033;
  display: flex;
  align-items: center;
  padding: 0 60px;
  position: relative;
  justify-content: space-between;
  .header-container {
    display: flex;
    align-items: center;
    a {
      padding: 0;
    }
    img {
      width: 40px;
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      a {
        padding: 0 30px;
        color: white;
        height: 100%;
        font-size: 15px;
        font-weight: 500;
        transition: transform ease-in-out 0.3s;
      }
    }
  }
  .profile-container{
    img{
      height: 35px;
      color: white;
    }
  }
}
</style>
