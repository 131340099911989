<template>
  <div class="index-app">
    <Header />
    <div class="views">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "@/components/dashboard/header.vue";
export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss">
.index-app {
  .views {
    height: calc(100vh - 60px);
    background: linear-gradient(180deg, #f9f9ff 0%, #f9f9ff 47%, #efeff2 100%);
  }
}
</style>
